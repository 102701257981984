import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        redPrimary: "#B22222",
        navDrawerColor: "#FBF7F7",
        buttonColor: "#e84b4b",
        pageBackground: "#f8f9fa",
        greyColorVariant1: "#cccccc",
        greyColorVariant2: "#333333",

        greenColorVariant1: "#cce5cc",
        greenColorVariant2: "#329932",

        redColorVariant1: "#ffb2b2",
        redColorVariant2: "#ff1919",

        blueColorVariant1: "#c7dcf6",
        blueColorVariant2: "#304b9d",
      },
    },
  },
});
