<template>
  <v-snackbar
    text
    v-model="snackBarComponent.SnackbarVmodel"
    :color="snackBarComponent.SnackbarColor"
    top
    class="snackbarClass"
    right
    :timeout="snackBarComponent.timeout"
    style="text-align: center">
    <!-- <div class="text-center" :class="`${snackBarComponent.SnackbarColor}--text`"></div> -->
    <v-icon left :color="snackBarComponent.SnackbarColor">{{
      snackBarComponent.SnackbarColor == "green" ? "mdi-check-circle" : "mdi-close-circle"
    }}</v-icon
    >{{ snackBarComponent.SnackbarText }}
  </v-snackbar>
</template>
<script>
export default {
  props: {
    snackBarComponent: Object,
  },
};
</script>

<style scoped>
.snackbarClass {
  text-align: center !important;
  font-weight: bold !important;
}
</style>
