<template>
  <v-app>
    <Snackbar :snackBarComponent="snackBarComponent" />
    <v-card tile :height="$vuetify.breakpoint.height" flat class="CardDesign">
      <v-row no-gutters>
        <v-col cols="12" md="8" align="center" justify="center" class="left-column hidden-sm-and-down">
          <!-- <div class="pr-16 pt-16">
            <v-img max-width="500px" src="../../assets/login.svg" />
          </div> -->
        </v-col>
        <v-col cols="12" md="4" class="right-column">
          <v-card class="elevation-0" color="transparent" width="300px">
            <div class="container">
              <img src="../../assets/intrologo-transparent.png" width="120" class="logo" />
            </div>
            <h1 class="">BMS - Login</h1>
            <v-form ref="LoginForm" class="pt-4">
              <v-row>
                <v-col cols="12">
                  <div class="text-left FontSize18px font-weight-bold">Email ID</div>
                  <v-text-field
                    outlined
                    dense
                    class="mt-2"
                    solo
                    placeholder="Enter email id"
                    flat
                    autocomplete="on"
                    :readonly="IsOTPFieldEnabled"
                    :filled="IsOTPFieldEnabled"
                    v-model.trim="Login.email_id"
                    :rules="[(v) => !!v || 'required']"
                    @keypress.enter.prevent="ValidateMethod(IsOTPFieldEnabled ? 'VERIFY_OTP' : 'SEND_OTP')"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <div class="FontSize12px text-left" v-if="IsOTPFieldEnabled">Please enter the OTP that was sent to your registered email:</div>
            <v-otp-input
              :length="6"
              v-show="IsOTPFieldEnabled"
              ref="autoFocusOtpField"
              class="FontSize12px"
              v-model="Login.otp"
              type="number"
              :rules="[(v) => !!v || 'required']"
              @finish="ValidateMethod(IsOTPFieldEnabled ? 'VERIFY_OTP' : 'SEND_OTP')"></v-otp-input>
            <div class="text-left">
              <v-btn
                v-if="IsOTPFieldEnabled && counter === 0"
                small
                text
                depressed
                block
                dark
                color="redPrimary"
                :disabled="counter !== 0"
                class="text-capitalize pa-0 ma-0 font-weight-bold"
                @click="SignInMethod('SEND_OTP')"
                >Resend OTP</v-btn
              >
              <span class="grey--text FontSize12px" v-if="IsOTPFieldEnabled == true && counter !== 0">Resend OTP available in {{ counter }}</span>
            </div>
            <v-card-actions class="pa-0">
              <v-btn
                color="redPrimary"
                class="text-capitalize"
                depressed
                :loading="LoginLoader"
                dark
                block
                @click="ValidateMethod(IsOTPFieldEnabled ? 'VERIFY_OTP' : 'SEND_OTP')"
                >{{ IsOTPFieldEnabled ? "Verify OTP" : "Get OTP" }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-app>
</template>

<script>
import { Auth } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
import axios from "axios";
export default {
  components: {
    Snackbar,
  },
  data: () => ({
    IsOTPFieldEnabled: false,
    LoginLoader: false,
    IsSessionLoggedOut: false,

    counter: 0,
    interval: null,
    SuccessMessage: "",

    snackBarComponent: {},
    Login: { email_id: "", otp: "" },
  }),
  mounted() {
    this.disableBrowserRoutingChanges();
    if (localStorage.getItem("IsLoggedOut") === "true") {
      this.IsSessionLoggedOut = true;
    } else {
      this.IsSessionLoggedOut = false;
    }
  },
  methods: {
    RouterMethod() {
      location.replace("http://callcentral.io/");
    },
    ValidateMethod(action) {
      if (this.$refs.LoginForm.validate()) {
        this.SignInMethod(action);
      } else {
        this.snackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: `Please enter Email to Login`,
        };
      }
    },
    async SignInMethod(action) {
      switch (action) {
        case "SEND_OTP":
          try {
            this.LoginLoader = true;
            this.user = await Auth.signIn(this.Login.email_id.toLocaleLowerCase());
            if (this.user.challengeName === "CUSTOM_CHALLENGE") {
              this.snackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "green",
                SnackbarText: `Verification OTP sent to ${this.Login.email_id.toLocaleLowerCase()}`,
              };

              this.counter = 60;
              this.LoginLoader = false;
              this.$refs.autoFocusOtpField.reset();
              setTimeout(() => {
                this.IsOTPFieldEnabled = true;
                setTimeout(() => {
                  this.$refs.autoFocusOtpField.focus();
                }, 100);
              }, 1000);
              this.$forceUpdate();
              this.startCounter();
            }
          } catch (error) {
            if (error.message === "DefineAuthChallenge failed with error Error: NOT_AUTHORIZED : Kindly Sigup.") {
              this.SignInMethod("SIGNUP");
            } else {
              this.LoginLoader = false;
              this.snackBarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "red",
                SnackbarText: error.message,
              };
            }

            // console.log("Err", error.message);
          }
          break;
        case "VERIFY_OTP":
          try {
            this.LoginLoader = true;
            await Auth.sendCustomChallengeAnswer(this.user, this.Login.otp)
              .then((data) => {
                console.log(data);
                this.snackBarComponent = {
                  SnackbarVmodel: true,
                  SnackbarColor: "green",
                  SnackbarText: "Login Successfull",
                };
                this.LoginLoader = false;
                localStorage.setItem("user_email_id", this.Login.email_id.toLocaleLowerCase());
                this.ActivateMethod();
              })
              .catch((err) => {
                if (
                  err.message === "VerifyAuthChallengeResponse failed with error Incorrect OTP!!." ||
                  err.message === "Invalid session for the user."
                ) {
                  this.counter = 0;
                  this.Login.otp = "";
                }
                this.snackBarComponent = {
                  SnackbarVmodel: true,
                  SnackbarColor: "red",
                  SnackbarText: err.message,
                };
                this.OTPErrorMessage = err.message;
                this.LoginLoader = false;
              });
          } catch (error) {
            // console.log("err", error);
          }
          break;
        // case "SIGNUP":
        //   try {
        //     let data = {
        //       command: "signupOrganization",
        //       organization_contact_person_email_address: `${this.Login.email_id.toLocaleLowerCase()}`,
        //     };
        //     let config = {
        //       method: "post",
        //       headers: {
        //         // "x-api-key": "IjTmpS9QCM8QcrIZZ2pHy64TS6oPdw6A7In2K1M7",
        //       },
        //       url: "https://7ivzfrhjih.execute-api.us-east-1.amazonaws.com/prod/organization_signup",
        //       data: data,
        //     };
        //     let result = await axios(config);
        //     if (result.data.status === "SUCCESS") {
        //       localStorage.setItem("USER_LOGGED_FIRST_TIME", "true");
        //       this.SignInMethod("SEND_OTP");
        //     }
        //   } catch (error) {
        //     this.SignUpLoader = false;
        //     this.AgreeSignUpLoader = false;
        //     // console.log("Err", error);
        //   }
        //   break;
      }
    },
    disableBrowserRoutingChanges() {
      window.history.pushState(null, "", window.location.href);
      window.onpopstate = function () {
        window.history.pushState(null, "", window.location.href);
      };
    },
    startCounter() {
      this.interval = setInterval(() => {
        if (this.counter > 0) {
          this.counter--;
        } else {
          clearInterval(this.interval);
        }
      }, 1000);
    },
    beforeDestroy() {
      clearInterval(this.interval);
    },
    ActivateMethod() {
      setTimeout(() => {
        localStorage.setItem("IsLoggedOut", false);
        this.$router.push("LandingPage");
      }, 1000);
    },
  },
};
</script>

<style>
.CardDesign {
  /* background: url("~@/assets/bg1.jpg") !important; */
  background-size: cover !important;

  background: #f8f9fa;
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100dvh !important;
}
.left-column {
  /* background-color: #f78ca0; */
  background-image: url(../../assets/login.svg);
  background-position: center;
  scale: 0.75;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh;
}
.right-column {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f8f9fa;
}
/* .wrapper {
  width: 450px;
  max-height: 450px;

  border-radius: 8px;
  padding: 30px;
  text-align: center;
  right: 150px !important;
  box-shadow: 20px 20px 10px !important;

  border: 1px solid rgba(255, 255, 255, 0.767);
  backdrop-filter: blur(10px);
  position: absolute !important;
  -webkit-backdrop-filter: blur(10px);
} */
</style>
