import Vue from "vue";
import VueRouter from "vue-router";
import LoginPage from "@/views/MainLandingPages/LoginPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: LoginPage,
    meta: {
      title: "Slotz - Login",
    },
  },
  {
    path: "/landingpage",
    name: "landingpage",

    component: () => import(/* webpackChunkName: "about" */ "@/views/MainLandingPages/LandingPage.vue"),
    meta: {
      title: "Landing Page",
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",

        component: () => import(/* webpackChunkName: "about" */ "@/views/chilldHomePages/Dashboard.vue"),
        meta: {
          title: "Dashboard",
        },
      },
      {
        path: "/listings",
        name: "listings",
        component: () => import(/* webpackChunkName: "about" */ "@/views/chilldHomePages/ListsPage.vue"),
        meta: {
          title: "Listings",
        },
        children: [
          {
            path: ":routeName",
            name: "listingDetails",
            component: () => import(/* webpackChunkName: "about" */ "@/components/Approvals/Cards/ViewApprovalsLocationDetails.vue"),
            meta: {
              title: "Listing Details",
            },
          },
        ],
      },

      {
        path: "/hosts",
        name: "hosts",

        component: () => import(/* webpackChunkName: "about" */ "@/views/chilldHomePages/HostsPage.vue"),
        meta: {
          title: "Hosts",
        },
        children: [
          {
            path: ":routeName",
            name: "hostDetails",
            component: () => import(/* webpackChunkName: "about" */ "@/components/Approvals/Cards/ViewAdminApprovalDetails.vue"),
            meta: {
              title: "Host Details",
            },
            children: [
              {
                path: ":locationrouteName",
                name: "hostDetailsLocationDetails",
                component: () => import(/* webpackChunkName: "about" */ "@/components/Approvals/Cards/ViewApprovalsLocationDetails.vue"),
                meta: {
                  title: "Host Details",
                },
              },
            ],
          },
        ],
      },
      {
        path: "/lists-approval",
        name: "lists-approval",

        component: () => import(/* webpackChunkName: "about" */ "@/views/chilldHomePages/ListApprovalPage.vue"),
        meta: {
          title: "Lists Approval",
        },
        children: [
          // this is child route ex: /lists-approval/list-name
          {
            path: ":routeName",
            name: "listingApprovalDetails",
            component: () => import(/* webpackChunkName: "about" */ "@/components/Approvals/Cards/ViewApprovalsLocationDetails.vue"),
            meta: {
              title: "Listing Approval Details",
            },
          },
        ],
      },
      {
        path: "/hosts-approval",
        name: "hosts-approval",

        component: () => import(/* webpackChunkName: "about" */ "@/views/chilldHomePages/HostApprovalPage.vue"),
        meta: {
          title: "Hosts Approval",
        },
        children: [
          {
            path: ":routeName",
            name: "hostApprovalDetails",
            component: () => import(/* webpackChunkName: "about" */ "@/components/Approvals/Cards/ViewAdminApprovalDetails.vue"),
            meta: {
              title: "Host Details",
            },
          },
        ],
      },
      {
        path: "/modified-listings",
        name: "modified-listings",

        component: () => import(/* webpackChunkName: "about" */ "@/views/chilldHomePages/ModifiedListings.vue"),
        children: [
          // this is child route ex: /lists-approval/list-name
          {
            path: ":routeName",
            name: "modifiedListingDetails",
            component: () => import(/* webpackChunkName: "about" */ "@/components/Approvals/Cards/ViewApprovalsLocationDetails.vue"),
            meta: {
              title: "Modified Listing Details",
            },
          },
        ],
        meta: {
          title: "Modified Listings",
        },
      },

      {
        path: "/referrals",
        name: "referrals",

        component: () => import(/* webpackChunkName: "about" */ "@/views/chilldHomePages/Referrals.vue"),
        meta: {
          title: "Referrals",
        },
      },
      {
        path: "/transactions",
        name: "transactions",

        component: () => import(/* webpackChunkName: "about" */ "@/views/chilldHomePages/Transactions.vue"),
        meta: {
          title: "Transactions",
        },
      },

      {
        path: "/reported-lists",
        name: "reported-lists",
        component: () => import(/* webpackChunkName: "about" */ "@/views/chilldHomePages/ReportedListings.vue"),
        meta: {
          title: "Reported lists",
        },
      },
      {
        path: "/app-users",
        name: "app-users",

        component: () => import(/* webpackChunkName: "about" */ "@/views/chilldHomePages/AppUsers.vue"),
        meta: {
          title: "App Users",
        },
      },
      {
        path: "/bms-users",
        name: "bms-users",

        component: () => import(/* webpackChunkName: "about" */ "@/views/chilldHomePages/UsersPage.vue"),
        meta: {
          title: "BMS Users",
        },
      },
      {
        path: "/audit-trail",
        name: "audit-trail",

        component: () => import(/* webpackChunkName: "about" */ "@/views/chilldHomePages/AuditTrails.vue"),
        meta: {
          title: "Audit Trail",
        },
      },

      {
        path: "/settings",
        name: "settings",

        component: () => import(/* webpackChunkName: "about" */ "@/views/chilldHomePages/Settings.vue"),
        meta: {
          title: "Settings",
        },
      },
      {
        path: "/release-notes",
        name: "release-notes",

        component: () => import(/* webpackChunkName: "about" */ "@/views/chilldHomePages/ReleaseNotes.vue"),
        meta: {
          title: "Release Notes",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  }
  next();
});
export default router;
